<template>
  <div class="user-avatar">
    <div class="user-avatar__wrapper">
      <img :src="image"
           class="user-avatar__avatar"
           :style="style"
           alt=""/>
    </div>
  </div>
</template>

<script>
import errorImage from '@/assets/images/svg/error.svg';

export default {
  name: 'UserAvatar',
  props: {
    params: {
      type: Object,
      required: true,
    },
  },
  computed: {
    image() {
      return this.params.avatar || errorImage;
    },
    style() {
      return {
        width: `${this.params.size}px`,
        height: `${this.params.size}px`,
      };
    },
  },
};
</script>
